var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-card",
    { attrs: { title: _vm.$t("lbl_list_job_costing") } },
    [
      _c(
        "a-card-grid",
        { staticStyle: { width: "100%" }, attrs: { hoverable: false } },
        [
          _c(
            "a-row",
            { attrs: { gutter: [16, 16], type: "flex" } },
            [
              _c(
                "a-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "a-form-model",
                    {
                      attrs: {
                        model: _vm.formModel,
                        "label-col": _vm.formCol.labelCol,
                        "wrapper-col": _vm.formCol.wrapperCol,
                        "label-align": "left"
                      }
                    },
                    [
                      _c(
                        "a-row",
                        { attrs: { gutter: [16, 16] } },
                        [
                          _c(
                            "a-col",
                            { attrs: { sm: 24, md: 12, lg: 8 } },
                            [
                              _c(
                                "a-form-model-item",
                                {
                                  attrs: { label: _vm.$t("lbl_customer_name") }
                                },
                                [
                                  _c("CSelectCustomer", {
                                    model: {
                                      value: _vm.formModel.customerName,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.formModel,
                                          "customerName",
                                          $$v
                                        )
                                      },
                                      expression: "formModel.customerName"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "a-col",
                            { attrs: { sm: 24, md: 12, lg: 8 } },
                            [
                              _c(
                                "a-form-model-item",
                                { attrs: { label: _vm.$t("lbl_order_date") } },
                                [
                                  _c("a-date-picker", {
                                    staticStyle: { width: "100%" },
                                    attrs: {
                                      placeholder: _vm.$t("lbl_choose"),
                                      format: _vm.DEFAULT_DATE_FORMAT
                                    },
                                    model: {
                                      value: _vm.formModel.orderDate,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.formModel,
                                          "orderDate",
                                          $$v
                                        )
                                      },
                                      expression: "formModel.orderDate"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "a-col",
                            { attrs: { sm: 24, md: 12, lg: 8 } },
                            [
                              _c(
                                "a-form-model-item",
                                { attrs: { label: _vm.$t("lbl_type") } },
                                [
                                  _c(
                                    "a-select",
                                    {
                                      staticStyle: { width: "100%" },
                                      attrs: {
                                        "allow-clear": "",
                                        loading: _vm.loading.type,
                                        placeholder: _vm.$t("lbl_choose")
                                      },
                                      model: {
                                        value: _vm.formModel.type,
                                        callback: function($$v) {
                                          _vm.$set(_vm.formModel, "type", $$v)
                                        },
                                        expression: "formModel.type"
                                      }
                                    },
                                    _vm._l(_vm.dtOpt.type, function(item) {
                                      return _c(
                                        "a-select-option",
                                        {
                                          key: item.key,
                                          attrs: { value: item.value }
                                        },
                                        [
                                          _c(
                                            "a-tooltip",
                                            [
                                              _c(
                                                "template",
                                                { slot: "title" },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(item.key || "-") +
                                                      " "
                                                  )
                                                ]
                                              ),
                                              _vm._v(
                                                " " +
                                                  _vm._s(item.key || "-") +
                                                  " "
                                              )
                                            ],
                                            2
                                          )
                                        ],
                                        1
                                      )
                                    }),
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "a-col",
                            { attrs: { sm: 24, md: 12, lg: 8 } },
                            [
                              _c(
                                "a-form-model-item",
                                { attrs: { label: _vm.$t("lbl_eta_date") } },
                                [
                                  _c("a-date-picker", {
                                    staticStyle: { width: "100%" },
                                    attrs: {
                                      placeholder: _vm.$t("lbl_choose"),
                                      format: _vm.DEFAULT_DATE_FORMAT
                                    },
                                    model: {
                                      value: _vm.formModel.etaDate,
                                      callback: function($$v) {
                                        _vm.$set(_vm.formModel, "etaDate", $$v)
                                      },
                                      expression: "formModel.etaDate"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "a-col",
                            { attrs: { sm: 24, md: 12, lg: 8 } },
                            [
                              _c(
                                "a-form-model-item",
                                { attrs: { label: _vm.$t("lbl_rfq_number") } },
                                [
                                  _c("a-input", {
                                    attrs: {
                                      "allow-clear": "",
                                      placeholder: _vm.$t("lbl_type_here")
                                    },
                                    model: {
                                      value: _vm.formModel.rfqNumber,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.formModel,
                                          "rfqNumber",
                                          $$v
                                        )
                                      },
                                      expression: "formModel.rfqNumber"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "a-col",
                            { attrs: { sm: 24, md: 12, lg: 8 } },
                            [
                              _c(
                                "a-form-model-item",
                                { attrs: { label: _vm.$t("lbl_status") } },
                                [
                                  _c(
                                    "a-select",
                                    {
                                      staticStyle: { width: "100%" },
                                      attrs: {
                                        "allow-clear": "",
                                        placeholder: _vm.$t("lbl_choose"),
                                        loading: _vm.loading.status
                                      },
                                      model: {
                                        value: _vm.formModel.status,
                                        callback: function($$v) {
                                          _vm.$set(_vm.formModel, "status", $$v)
                                        },
                                        expression: "formModel.status"
                                      }
                                    },
                                    _vm._l(_vm.dtOpt.status, function(item) {
                                      return _c(
                                        "a-select-option",
                                        {
                                          key: item.key,
                                          attrs: { value: item.value }
                                        },
                                        [
                                          _c(
                                            "a-tooltip",
                                            [
                                              _c(
                                                "template",
                                                { slot: "title" },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(item.key || "-") +
                                                      " "
                                                  )
                                                ]
                                              ),
                                              _vm._v(
                                                " " +
                                                  _vm._s(item.key || "-") +
                                                  " "
                                              )
                                            ],
                                            2
                                          )
                                        ],
                                        1
                                      )
                                    }),
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "a-col",
                            { attrs: { sm: 24, md: 12, lg: 8 } },
                            [
                              _c(
                                "a-form-model-item",
                                {
                                  attrs: {
                                    label: _vm.$t("lbl_job_costing_number")
                                  }
                                },
                                [
                                  _c("a-input", {
                                    attrs: {
                                      "allow-clear": "",
                                      placeholder: _vm.$t("lbl_type_here")
                                    },
                                    model: {
                                      value: _vm.formModel.jobCostingNumber,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.formModel,
                                          "jobCostingNumber",
                                          $$v
                                        )
                                      },
                                      expression: "formModel.jobCostingNumber"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "a-col",
                            { attrs: { sm: 24, md: 12, lg: 8 } },
                            [
                              _c(
                                "a-form-model-item",
                                { attrs: { label: _vm.$t("lbl_branch") } },
                                [
                                  _c("CSelectBranch", {
                                    model: {
                                      value: _vm.formModel.branch,
                                      callback: function($$v) {
                                        _vm.$set(_vm.formModel, "branch", $$v)
                                      },
                                      expression: "formModel.branch"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "a-row",
            {
              attrs: {
                gutter: [16, 16],
                type: "flex",
                justify: "space-between",
                align: "middle"
              }
            },
            [
              _c(
                "a-col",
                [
                  _c(
                    "a-space",
                    [
                      _c("a-button", { on: { click: _vm.handleClear } }, [
                        _vm._v(" " + _vm._s(_vm.$t("lbl_clear")) + " ")
                      ]),
                      _c(
                        "a-button",
                        {
                          attrs: { type: "primary", icon: "search" },
                          on: { click: _vm.handleFind }
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("lbl_find")) + " ")]
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "a-col",
                [
                  _vm.isAllowedToPick
                    ? _c(
                        "a-button",
                        {
                          attrs: { type: "primary", icon: "plus" },
                          on: { click: _vm.handleCreateNew }
                        },
                        [
                          _vm._v(
                            " " + _vm._s(_vm.$t("lbl_new_carton_unload")) + " "
                          )
                        ]
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "a-card-grid",
        { staticStyle: { width: "100%" }, attrs: { hoverable: false } },
        [
          _c(
            "a-row",
            { attrs: { gutter: [16, 16], type: "flex" } },
            [
              _c(
                "a-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "a-table",
                    {
                      attrs: {
                        "data-source": _vm.dtListJobCosting.data,
                        scroll: { x: 1300, y: 520 },
                        pagination: false,
                        loading: _vm.loading.table
                      }
                    },
                    [
                      _c(
                        "a-table-column",
                        {
                          key: "customerName",
                          attrs: { "data-index": "customerName" },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function(customerName) {
                                return [
                                  _vm._v(
                                    " " + _vm._s(customerName || "-") + " "
                                  )
                                ]
                              }
                            }
                          ])
                        },
                        [
                          _c(
                            "span",
                            { attrs: { slot: "title" }, slot: "title" },
                            [_vm._v(_vm._s(_vm.$t("lbl_customer_name")))]
                          )
                        ]
                      ),
                      _c(
                        "a-table-column",
                        {
                          key: "rfqNumber",
                          attrs: { "data-index": "rfqNumber" },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function(rfqNumber) {
                                return [
                                  _vm._v(" " + _vm._s(rfqNumber || "-") + " ")
                                ]
                              }
                            }
                          ])
                        },
                        [
                          _c(
                            "span",
                            { attrs: { slot: "title" }, slot: "title" },
                            [_vm._v(_vm._s(_vm.$t("lbl_rfq_number")))]
                          )
                        ]
                      ),
                      _c(
                        "a-table-column",
                        {
                          key: "jobCostingNumber",
                          attrs: { "data-index": "jobCostingNumber" },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function(jobCostingNumber) {
                                return [
                                  _vm._v(
                                    " " + _vm._s(jobCostingNumber || "-") + " "
                                  )
                                ]
                              }
                            }
                          ])
                        },
                        [
                          _c(
                            "span",
                            { attrs: { slot: "title" }, slot: "title" },
                            [_vm._v(_vm._s(_vm.$t("lbl_job_costing_number")))]
                          )
                        ]
                      ),
                      _c(
                        "a-table-column",
                        {
                          key: "orderDate",
                          attrs: { "data-index": "orderDate" },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function(orderDate) {
                                return [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        orderDate
                                          ? _vm
                                              .moment(orderDate)
                                              .format(_vm.DEFAULT_DATE_FORMAT)
                                          : "-"
                                      ) +
                                      " "
                                  )
                                ]
                              }
                            }
                          ])
                        },
                        [
                          _c(
                            "span",
                            { attrs: { slot: "title" }, slot: "title" },
                            [_vm._v(_vm._s(_vm.$t("lbl_order_date")))]
                          )
                        ]
                      ),
                      _c(
                        "a-table-column",
                        {
                          key: "etaDate",
                          attrs: { "data-index": "etaDate" },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function(etaDate) {
                                return [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        etaDate
                                          ? _vm
                                              .moment(etaDate)
                                              .format(
                                                _vm.DEFAULT_DATE_FORMAT_TIME
                                              )
                                          : "-"
                                      ) +
                                      " "
                                  )
                                ]
                              }
                            }
                          ])
                        },
                        [
                          _c(
                            "span",
                            { attrs: { slot: "title" }, slot: "title" },
                            [_vm._v(_vm._s(_vm.$t("lbl_delivery_date")))]
                          )
                        ]
                      ),
                      _c(
                        "a-table-column",
                        {
                          key: "branchName",
                          attrs: { "data-index": "branchName" },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function(branchName) {
                                return [
                                  _vm._v(" " + _vm._s(branchName || "-") + " ")
                                ]
                              }
                            }
                          ])
                        },
                        [
                          _c(
                            "span",
                            { attrs: { slot: "title" }, slot: "title" },
                            [_vm._v(_vm._s(_vm.$t("lbl_branch")))]
                          )
                        ]
                      ),
                      _c(
                        "a-table-column",
                        {
                          key: "journalNumber",
                          attrs: { "data-index": "journalNumber" },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function(text, ref) {
                                var journalNumber = ref.journalNumber
                                var journalId = ref.journalId
                                return [
                                  !!journalNumber
                                    ? _c(
                                        "router-link",
                                        {
                                          attrs: {
                                            to: {
                                              name: "generaljournal.detail",
                                              params: { id: journalId }
                                            }
                                          }
                                        },
                                        [
                                          _vm._v(
                                            " " + _vm._s(journalNumber) + " "
                                          )
                                        ]
                                      )
                                    : _c("span", [_vm._v(" - ")])
                                ]
                              }
                            }
                          ])
                        },
                        [
                          _c(
                            "span",
                            { attrs: { slot: "title" }, slot: "title" },
                            [_vm._v(_vm._s(_vm.$t("lbl_journal_number")))]
                          )
                        ]
                      ),
                      _c(
                        "a-table-column",
                        {
                          key: "type",
                          attrs: { "data-index": "type" },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function(type) {
                                return [_vm._v(" " + _vm._s(type || "-") + " ")]
                              }
                            }
                          ])
                        },
                        [
                          _c(
                            "span",
                            { attrs: { slot: "title" }, slot: "title" },
                            [_vm._v(_vm._s(_vm.$t("lbl_type")))]
                          )
                        ]
                      ),
                      _c(
                        "a-table-column",
                        {
                          key: "status",
                          attrs: {
                            "data-index": "status",
                            fixed: "right",
                            width: 120
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function(text, record) {
                                return [
                                  _vm._v(
                                    " " + _vm._s(record.status || "-") + " "
                                  )
                                ]
                              }
                            }
                          ])
                        },
                        [
                          _c(
                            "span",
                            { attrs: { slot: "title" }, slot: "title" },
                            [_vm._v(_vm._s(_vm.$t("lbl_status")))]
                          )
                        ]
                      ),
                      _vm.isAllowedToPick || _vm.isAllowedToProcess
                        ? _c(
                            "a-table-column",
                            {
                              key: "action",
                              attrs: {
                                fixed: "right",
                                width: 100,
                                align: "center"
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function(text, record) {
                                      return [
                                        _c(
                                          "a-dropdown",
                                          {
                                            attrs: {
                                              trigger: ["click"],
                                              placement: "bottomCenter"
                                            }
                                          },
                                          [
                                            _c("a-icon", {
                                              attrs: { type: "more" },
                                              on: {
                                                click: function(e) {
                                                  return e.preventDefault()
                                                }
                                              }
                                            }),
                                            _c(
                                              "a-menu",
                                              {
                                                attrs: { slot: "overlay" },
                                                slot: "overlay"
                                              },
                                              [
                                                _vm.isAllowedToPick
                                                  ? _c(
                                                      "a-menu-item",
                                                      {
                                                        on: {
                                                          click: function(
                                                            $event
                                                          ) {
                                                            return _vm.gotoJobCostingWarehouse(
                                                              record
                                                            )
                                                          }
                                                        }
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              record.status ===
                                                                _vm
                                                                  .JOB_COSTING_STATUS
                                                                  .QUOTATION_SUBMITTED
                                                                ? _vm.$t(
                                                                    "lbl_pick"
                                                                  )
                                                                : _vm.$t(
                                                                    "lbl_view_picked"
                                                                  )
                                                            ) +
                                                            " "
                                                        )
                                                      ]
                                                    )
                                                  : _vm._e(),
                                                _vm.isAllowedToProcess &&
                                                record.type ===
                                                  _vm.JOB_COSTING_TYPE
                                                    .MEAT_ROOM_PROCESS &&
                                                record.status !==
                                                  _vm.JOB_COSTING_STATUS
                                                    .QUOTATION_SUBMITTED
                                                  ? _c(
                                                      "a-menu-item",
                                                      {
                                                        on: {
                                                          click: function(
                                                            $event
                                                          ) {
                                                            return _vm.gotoJobCostingMeatroom(
                                                              record
                                                            )
                                                          }
                                                        }
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              record.status ===
                                                                _vm
                                                                  .JOB_COSTING_STATUS
                                                                  .WAREHOUSE_PICKED ||
                                                                record.status ===
                                                                  _vm
                                                                    .JOB_COSTING_STATUS
                                                                    .PARTIAL_PROCESS ||
                                                                record.status ===
                                                                  _vm
                                                                    .JOB_COSTING_STATUS
                                                                    .PARTIAL_COMPLETED
                                                                ? _vm.$t(
                                                                    "lbl_process"
                                                                  )
                                                                : _vm.$t(
                                                                    "lbl_view_processed"
                                                                  )
                                                            ) +
                                                            " "
                                                        )
                                                      ]
                                                    )
                                                  : _vm._e(),
                                                _vm.isAllowedToProcess &&
                                                record.type ===
                                                  _vm.JOB_COSTING_TYPE
                                                    .MEAT_ROOM_PROCESS &&
                                                (record.status ===
                                                  _vm.JOB_COSTING_STATUS
                                                    .PARTIAL_COMPLETED ||
                                                  record.status ===
                                                    _vm.JOB_COSTING_STATUS
                                                      .PARTIAL_PROCESS ||
                                                  record.status ===
                                                    _vm.JOB_COSTING_STATUS
                                                      .PROCESSED ||
                                                  record.status ===
                                                    _vm.JOB_COSTING_STATUS
                                                      .COMPLETED)
                                                  ? _c(
                                                      "a-menu-item",
                                                      {
                                                        on: {
                                                          click: function(
                                                            $event
                                                          ) {
                                                            return _vm.gotoPostProcess(
                                                              record
                                                            )
                                                          }
                                                        }
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              record.status ===
                                                                _vm
                                                                  .JOB_COSTING_STATUS
                                                                  .PROCESSED ||
                                                                record.status ===
                                                                  _vm
                                                                    .JOB_COSTING_STATUS
                                                                    .PARTIAL_PROCESS ||
                                                                record.status ===
                                                                  _vm
                                                                    .JOB_COSTING_STATUS
                                                                    .PARTIAL_COMPLETED
                                                                ? _vm.$t(
                                                                    "lbl_post_process"
                                                                  )
                                                                : _vm.$t(
                                                                    "lbl_view_post_processed"
                                                                  )
                                                            ) +
                                                            " "
                                                        )
                                                      ]
                                                    )
                                                  : _vm._e()
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        )
                                      ]
                                    }
                                  }
                                ],
                                null,
                                false,
                                4045677894
                              )
                            },
                            [
                              _c(
                                "span",
                                { attrs: { slot: "title" }, slot: "title" },
                                [_vm._v(_vm._s(_vm.$t("lbl_action")))]
                              )
                            ]
                          )
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c("a-divider"),
          _c(
            "a-row",
            {
              attrs: {
                gutter: [16, 16],
                type: "flex",
                justify: "space-between",
                align: "middle"
              }
            },
            [
              _c(
                "a-col",
                { attrs: { sm: 24, md: 18 } },
                [
                  _c("Pagination", {
                    attrs: {
                      total: _vm.dtListJobCosting.totalElements,
                      "page-size-set": _vm.queryParams.limit,
                      "id-pagination": "pagination1"
                    },
                    on: {
                      "response-pagesize-change": _vm.responsePageSizeChange,
                      "response-currentpage-change":
                        _vm.responseCurrentPageChange
                    }
                  })
                ],
                1
              ),
              _c(
                "a-col",
                { attrs: { sm: 24, md: 6, align: "end" } },
                [
                  _c("a-tag", { attrs: { color: "grey" } }, [
                    _vm._v(
                      " " +
                        _vm._s(_vm.$t("lbl_total_found")) +
                        " : " +
                        _vm._s(_vm.dtListJobCosting.totalElements) +
                        " "
                    )
                  ])
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }