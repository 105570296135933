










































































































































































































































































































































































// core
import Vue from "vue";
import moment from "moment";
import { DEFAULT_DATE_FORMAT_TIME, DEFAULT_DATE_FORMAT } from "@constant/date.constant";
import { RequestQueryParamsModel } from "@/models/interface/http.interface";
import { ResponseListContactData } from "@interface/contact.interface";
import { contactServices } from "@service/contact.service";
import { jobCostingService } from "@service/job-costing.service";
import MNotificationVue from "@/mixins/MNotification.vue";
import { ResponseJobCosting, ResponseListJobCosting } from "@interface/job-costing.interface";
import { ResponsePagination } from "@/models/interface/common.interface";
import { masterTypeService } from "@service/master-type.service";
import { ResponseListMasterType } from "@interface/master.interface";
import { JOB_COSTING_STATUS, JOB_COSTING_TYPE } from "@/models/enums/job-costing.enum";
import { trimSpaceToUnderscore } from "@/helpers/common";
import { mapGetters } from "vuex";
import { DEFAULT_PAGE_SIZE } from "@/models/constant/global.constant";

export default Vue.extend({
  name: "ListJobCosting",
  components: {
    CSelectCustomer: () => import("@/components/shared/select-customer/CSelectCustomer.vue")
  },
  mixins: [
    MNotificationVue,
  ],
  data() {
    return {
      JOB_COSTING_STATUS,
      JOB_COSTING_TYPE,
      DEFAULT_DATE_FORMAT,
      DEFAULT_DATE_FORMAT_TIME,
      formModel: {
        customerName: undefined as string | undefined, // hold customer id
        type: undefined as string | undefined,
        rfqNumber: undefined as string | undefined,
        jobCostingNumber: undefined as string | undefined,
        branch: undefined as string | undefined, // hold location rack id
        orderDate: null as string | null,
        etaDate: null as string | null,
        status: undefined as string | undefined
      },
      dtOpt: {
        customerName: [] as { key: string, value: string }[],
        type: [] as { key: string, value: string }[],
        status: [] as { key: string, value: string }[],
      },
      loading: {
        customerName: false,
        table: false,
        status: false,
        type: false
      },
      queryParams: {
        page: 0,
        limit: DEFAULT_PAGE_SIZE,
        sorts: "createdDate:desc"
      } as RequestQueryParamsModel,
      dtListJobCosting: {} as ResponseListJobCosting,
    };
  },
  computed: {
    ...mapGetters({
      userPrivileges: "authStore/GET_USER_PRIVILEGES"
    }),
    formCol() {
      return {
        labelCol: {
          sm: { span: 24 },
          md: { span: 8 },
        },
        wrapperCol: {
          sm: { span: 24 },
          md: { span: 16 },
        },
      };
    },
    isAllowedToPick(): boolean {
      const priv = this.userPrivileges.find(x => x.key === "job-costing-warehouse" && (x.privilege.create || x.privilege.update));
      if (priv) return true;
      return false;
    },
    isAllowedToProcess(): boolean {
      const priv = this.userPrivileges.find(x => x.key === "job-costing-meatroom" && (x.privilege.create || x.privilege.update));
      return !!priv;
    },
    hasPrivJobCostingWarehouse(): boolean {
      const priv = this.userPrivileges.find(x => x.key === "job-costing-warehouse");
      return !!priv;
    },
    hasPrivJobCostingMeatroom(): boolean {
      const priv = this.userPrivileges.find(x => x.key === "job-costing-meatroom");
      return !!priv;
    }
  },
  created() {
    this.fetchListJobCosting();
    this.getListStatus();
    this.getListType();
  },
  methods: {
    moment,
    getListCustomer(params: RequestQueryParamsModel): Promise<ResponseListContactData> {
      return contactServices.listContactData(params);
    },
    /**
     * get list job costing
     * @role admin
     */
    getListJobCosting(params: RequestQueryParamsModel): Promise<ResponseListJobCosting> {
      return jobCostingService.getListJobCosting(params);
    },
    getListMasterType(params: RequestQueryParamsModel): Promise<ResponseListMasterType[]> {
      return masterTypeService.listMaster(params);
    },
    gotoJobCostingWarehouse(record: ResponseJobCosting): void {
      if (record.type === JOB_COSTING_TYPE.CARTON_UNLOAD) {
        if (record.status === JOB_COSTING_STATUS.PROCESSED) {
          this.$router.push({ name: "sales.transactionsales.jobcosting.loaf.view", params: { id: record.id } });
        }
      } else if (record.type === JOB_COSTING_TYPE.MEAT_ROOM_PROCESS) {
        if (record.status === JOB_COSTING_STATUS.QUOTATION_SUBMITTED) {
          this.$router.push({ name: "sales.transactionsales.jobcosting.warehousepicked.create", params: { id: record.id } });
        } else {
          this.$router.push({ name: "sales.transactionsales.jobcosting.warehousepicked.view", params: { id: record.id } });
        }
      }
    },
    gotoJobCostingMeatroom(record: ResponseJobCosting): void {
      if (record.status === JOB_COSTING_STATUS.WAREHOUSE_PICKED || record.status === JOB_COSTING_STATUS.PARTIAL_PROCESS || record.status === JOB_COSTING_STATUS.PARTIAL_COMPLETED) {
        this.$router.push({ name: "sales.transactionsales.jobcosting.meatroom.create", params: { id: record.id }, query: { status: (record.status), } });
      } else {
        this.$router.push({ name: "sales.transactionsales.jobcosting.meatroom.view", params: { id: record.id }, query: { status: (record.status), } });
      }
    },
    gotoPostProcess(record: ResponseJobCosting): void {
      this.$router.push({ name: "sales.transactionsales.jobcosting.post.meatroom.create", params: { id: record.id }, query: { status: trimSpaceToUnderscore(record.status) }});
    },
    handleCreateNew(): void {
      this.$router.push({ name: "sales.transactionsales.jobcosting.loaf.create" });
    },
    responsePageSizeChange(response: ResponsePagination): void {
      this.queryParams.limit = response.size;
      this.queryParams.page = 0;
      this.fetchListJobCosting();
    },
    responseCurrentPageChange(response: ResponsePagination): void {
      this.queryParams.page = response.page - 1;
      this.fetchListJobCosting();
    },
    handleClear(): void {
      this.formModel = {
        customerName: "", // hold customer id
        type: "",
        rfqNumber: "",
        jobCostingNumber: "",
        branch: "",
        orderDate: null as string | null,
        etaDate: null as string | null,
        status: ""
      };
      if (this.queryParams.search) {
        delete this.queryParams.search;
      }
    },
    async fetchListJobCosting(): Promise<void> {
      try {
        this.loading.table = true;
        const params: RequestQueryParamsModel = {...this.queryParams};
        if (this.hasPrivJobCostingWarehouse && this.hasPrivJobCostingMeatroom) {
          const res = await this.getListJobCosting(params);
          this.dtListJobCosting = res;
        } else if (this.hasPrivJobCostingWarehouse) {
          const res = await jobCostingService.getListJobCostingWarehouse(params);
          this.dtListJobCosting = res;
        } else if (this.hasPrivJobCostingMeatroom) {
          const res = await jobCostingService.getListJobCostingMeatroom(params);
          this.dtListJobCosting = res;
        }
        this.dtListJobCosting.data.forEach((x, i) => x["key"] = i);
      } catch (error) {
        this.showErrorMessage("notif_process_fail");
      } finally {
        this.loading.table = false;
      }
    },
    handleFind(): void {
      const searchBy: string[] = [];
      const {
        customerName,
        type,
        orderDate,
        etaDate,
        rfqNumber,
        status,
        jobCostingNumber,
        branch
      } = this.formModel;

      if (this.queryParams.search) delete this.queryParams.search;

      if (customerName) {
        searchBy.push(`customer.secureId~${customerName}`);
      }

      if (type) {
        searchBy.push(`type~${type.replace(/\s/gi, "_").toUpperCase()}`);
      }

      if (rfqNumber) {
        searchBy.push(`quotation.rfqNumber~*${rfqNumber}*`);
      }

      if (status) {
        searchBy.push(`status~${status.replace(/\s/gi, "_").toUpperCase()}`);
      }

      if (jobCostingNumber) {
        searchBy.push(`jobCostingNumber~*${jobCostingNumber}*`);
      }

      if (branch) {
        searchBy.push(`branch.secureId~*${branch}*`);
      }

      if (orderDate) {
        const [start, end] = [
          this.moment(orderDate).set({ hour: 0, minute: 0, second: 0 }).utcOffset("+07").format(),
          this.moment(orderDate).set({ hour: 23, minute: 59, second: 59 }).utcOffset("+07").format(),
        ];
        searchBy.push(`orderDate>=${start}_AND_orderDate<=${end}`);
      }

      if (etaDate) {
        const [start, end] = [
          this.moment(etaDate).set({ hour: 0, minute: 0, second: 0 }).utcOffset("+07").format(),
          this.moment(etaDate).set({ hour: 23, minute: 59, second: 59 }).utcOffset("+07").format(),
        ];
        searchBy.push(`etaDate>=${start}_AND_etaDate<=${end}`);
      }

      if (searchBy.length) {
        this.queryParams.search = searchBy.join("_AND_");
      }
      this.fetchListJobCosting();
    },
    async getListStatus(): Promise<void> {
      try {
        this.loading.status = true;
        const res = await this.getListMasterType({ name: "RFQ_STATUS" });
        this.dtOpt.status = res.map(x => { return { key: x.value, value: x.value };});
      } catch (error) {
        this.showErrorMessage("notif_process_fail");
      } finally {
        this.loading.status = false;
      }
    },
    async getListType(): Promise<void> {
      try {
        this.loading.type = true;
        const res = await this.getListMasterType({ name: "JOB_COSTING_TYPE" });
        this.dtOpt.type = res.map(x => { return { key: x.value, value: x.value };});
      } catch (error) {
        this.showErrorMessage("notif_process_fail");
      } finally {
        this.loading.type = false;
      }
    },
    ableToPostProcess(record: ResponseJobCosting): boolean {
      return record.status === JOB_COSTING_STATUS.PROCESSED || record.status === JOB_COSTING_STATUS.PARTIAL_PROCESS || record.status === JOB_COSTING_STATUS.COMPLETED || record.status === JOB_COSTING_STATUS.PARTIAL_COMPLETED;
    }
  }
});

