import { Api } from "@/models/class/api.class";
import {
  RequestJobCostingLoafCreate,
  ResponseJobCostingLoafDetail,
} from "@interface/job-costing-loaf.interface";
import {
  RequestJobCostingUpdate,
  ResponseAddBatchNumber,
  ResponseJobCostingDetail,
  ResponseListJobCosting,
} from "@interface/job-costing.interface";
import {
  ErrorResponseModel,
  RequestQueryParamsModel,
} from "@/models/interface/http.interface";
import { HttpClient } from "@/services/http.service";
import { AxiosError } from "axios";
import { IGenericResponsePost } from "@interface/common.interface";
import {
  JobCostingMeatroomProduceConsumeProductsDetail,
  RequestAddBatchConsumeProduct,
  RequestAddConsumeProduct,
  RequestJobCostingMeatroomUpdate,
  ResponseJobCostingMeatroomDetail,
} from "@interface/job-costing-meatroom.interface";

export class JobCostingServices extends HttpClient {
  constructor() {
    super();
  }

  /**
   * get all job costing from warehouse/meatroom and loanf
   * @param params query params
   * @returns response list job costing
   */
  getListJobCosting(
    params: RequestQueryParamsModel
  ): Promise<ResponseListJobCosting> {
    return this.get<ResponseListJobCosting>(Api.JobCosting, { params })
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  /**
   * get list job costing as warehouse
   * @param params query params
   * @returns list job costing
   */
  getListJobCostingWarehouse(
    params: RequestQueryParamsModel
  ): Promise<ResponseListJobCosting> {
    return this.get<ResponseListJobCosting>(`${Api.JobCosting}/warehouse`, {
      params,
    })
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  /**
   * get list job costing as meatroom
   * @param params query params
   * @returns list job costing
   */
  getListJobCostingMeatroom(
    params: RequestQueryParamsModel
  ): Promise<ResponseListJobCosting> {
    return this.get<ResponseListJobCosting>(`${Api.JobCosting}/meatroom`, {
      params,
    })
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  /**
   * get detail of job costing warehouse pick
   * @param jobCostingId job costing id
   * @returns response detail job costing warehouse picked
   */
  getDetailJobCostingWarehouse(
    jobCostingId: string
  ): Promise<ResponseJobCostingDetail> {
    return this.get<ResponseJobCostingDetail>(
      `${Api.JobCosting}/warehouse/${jobCostingId}`
    )
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  updateJobCostingWarehouse(
    jobCostingId: string,
    payload: RequestJobCostingUpdate
  ): Promise<IGenericResponsePost> {
    return this.put<IGenericResponsePost, RequestJobCostingUpdate>(
      `${Api.JobCosting}/warehouse/${jobCostingId}`,
      payload
    )
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  /**
   * get job costing meatroom
   * @param jobCostingId job costing id
   * @returns detail job costing meatroom
   */
  getDetailJobCostingMeatroom(
    jobCostingId: string
  ): Promise<ResponseJobCostingMeatroomDetail> {
    return this.get<ResponseJobCostingMeatroomDetail>(
      `${Api.JobCosting}/meatroom/${jobCostingId}`
    )
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  updateJobCostingMeatroom(
    jobCostingId: string,
    payload: RequestJobCostingMeatroomUpdate
  ): Promise<IGenericResponsePost> {
    return this.put<IGenericResponsePost, RequestJobCostingMeatroomUpdate>(
      `${Api.JobCosting}/meatroom/${jobCostingId}`,
      payload
    )
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  createJobCostingLoaf(
    payload: RequestJobCostingLoafCreate
  ): Promise<IGenericResponsePost> {
    return this.post<IGenericResponsePost, RequestJobCostingLoafCreate>(
      `${Api.JobCosting}/loaf`,
      payload
    )
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  getDetailJobCostingLoaf(id: string): Promise<ResponseJobCostingLoafDetail> {
    return this.get<ResponseJobCostingLoafDetail>(
      `${Api.JobCosting}/loaf/${id}`
    )
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  returnBatchNumber(id: string): Promise<boolean> {
    return this.delete<boolean>(`${Api.JobCosting}/meatroom/batch-number/${id}`)
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  addBatchNumber(
    id: string,
    payload: RequestAddBatchConsumeProduct[]
  ): Promise<ResponseAddBatchNumber[]> {
    return this.put<ResponseAddBatchNumber[], RequestAddBatchConsumeProduct[]>(
      `${Api.JobCosting}/meatroom/batch-number/${id}`,
      payload
    )
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  addConsumeProduct(
    payload: RequestAddConsumeProduct
  ): Promise<JobCostingMeatroomProduceConsumeProductsDetail> {
    return this.post<
      JobCostingMeatroomProduceConsumeProductsDetail,
      RequestAddConsumeProduct
    >(`${Api.JobCosting}/meatroom/consume-product`, payload)
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  deleteConsumeProduct(consumeId: string, productId: string): Promise<boolean> {
    return this.delete<boolean>(
      `${Api.JobCosting}/meatroom/consume-product/${consumeId}/${productId}`
    )
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  cancelWarehousePick(jobCostingId: string): Promise<boolean> {
    return this.delete<boolean>(
      `${Api.JobCosting}/warehouse/cancel/${jobCostingId}`
    )
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }
}

export const jobCostingService = new JobCostingServices();
