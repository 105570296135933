export enum JOB_COSTING_STATUS {
  CANCELLED = "Cancelled",
  PROCESSED = "Processed",
  QUOTATION_SUBMITTED = "Quotation Submitted",
  SALES_ORDER_CREATED = "Sales Order Created",
  WAREHOUSE_PICKED = "Warehouse Picked",
  PARTIAL_PROCESS = "Partial Process",
  COMPLETED = "Completed",
  PARTIAL_COMPLETED = "Partial Completed",
  // POST_MEATROOM_PROCESSED = "Post Meatroom Processed", // unused
}

export enum JOB_COSTING_TYPE {
  CARTON_UNLOAD = "Carton Unload", // job costing warehouse loaf
  MEAT_ROOM_PROCESS = "Meat Room Process" // job costing warehouse rfq
}

export enum PRODUCE_PRODUCT_STATUS {
  PROCESSED = "Processed",
  NEED_PROCESSED = "Need Processed",
  READY_TO_MEATROOM = "Ready To Post Meatroom",
}

export enum POST_MEATROOM_LINE_STATUS {
  PROCESSED = "Processed",
  READY_TO_MEATROOM = "Ready To Post Meatroom",
  NEED_PROCESSED = "Need Processed"
}

export enum JOB_COSTING_LOCAL_STORAGE {
  PROCESS_IN = "process_in"
}

export enum JOB_COSTING_LOAF_LOCAL_STORAGE {
  PROCESS_IN = "loaf_process_in"
}
